import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../../theme/GlobalCss';
import '../../../services/api/index';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import { LAYOUT_STYLES, LAYOUT_TYPES } from '../../constants/ThemeOptions';
import { useLocation } from 'react-router-dom';
import { THEME_COLORS } from '../../constants/CustomizerOptions';
import InitWidget from '../../../routes/Dashboards/Elrond/InitWidget';
import MinimalNoHeader from './VerticalLayouts/MinimalNoHeader';
import HorizontalDefault from './HorizontalLayouts/HorizontalDefault';
import HorizontalDark from './HorizontalLayouts/HorizontalDark';
import HorizontalTopMenu from './HorizontalLayouts/HorizontalTopMenu';
import HorizontalMinimal from './HorizontalLayouts/HorizontalMinimal';

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [isTemplateLoaded, setTemplateLoading] = useState(false);
  const { layout, setLayout, layoutStyle, setLayoutStyle, themeType, updateThemeType, updateThemeColors } = useContext(
    AppContext,
  );
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();

  useEffect(() => {
    dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
    updateThemeType(themeType);
    setTemplateLoading(true);

    const params = new URLSearchParams(location.search);
    if (params.get('layout')) {
      setLayout(params.get('layout'));
    }

    if (params.get('layout-style')) {
      setLayoutStyle(params.get('layout-style'));
    }

    if (params.get('theme-type')) {
      updateThemeType(params.get('theme-type'));
    }

    updateThemeColors(THEME_COLORS[THEME_COLORS.length - 1]);
  }, []);

  useEffect(() => {
    setLayoutType();
  }, [layoutStyle]);

  const setLayoutType = () => {
    if (layoutStyle === LAYOUT_STYLES.FULL_WIDTH) {
      document.body.classList.remove('layout-type-boxed');
      document.body.classList.remove('layout-type-framed');
      document.body.classList.add('layout-type-fullwidth');
    } else if (layoutStyle === LAYOUT_STYLES.BOXED) {
      document.body.classList.remove('layout-type-fullwidth');
      document.body.classList.remove('layout-type-framed');
      document.body.classList.add('layout-type-boxed');
    } else if (layoutStyle === LAYOUT_STYLES.FRAMED) {
      document.body.classList.remove('layout-type-boxed');
      document.body.classList.remove('layout-type-fullwidth');
      document.body.classList.add('layout-type-framed');
    }
  };

  if (!isTemplateLoaded || !loadUser) {
    return (
      <Box className={classes.circularProgressRoot}>
        <CircularProgress />
      </Box>
    );
  }

  if (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password') {
    return (
      <Box display="flex" width={1} style={{ minHeight: '100vh' }}>
        {children}
      </Box>
    );
  }

  return (
    <Fragment>
      <HorizontalMinimal children={children} />
      <InitWidget />
    </Fragment>
  );
};

export default AppLayout;
