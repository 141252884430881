import React from 'react';
import AppWrapper from '@jumbo/components/AppWrapper';

const Home = React.lazy(() => import('./routes/pages/Home/index'));
const Elrond = React.lazy(() => import('./routes/Dashboards/Elrond'));
const ElrondLogin = React.lazy(() => import('./routes/Dashboards/ElrondLogin'));
const Privacy = React.lazy(() => import('./routes/pages/Utility/PagePrivacy'));
const Terms = React.lazy(() => import('./routes/pages/Utility/PageTerms'));
const Ledger = React.lazy(() => import('./routes/pages/Ledger'));

//Help Center
const HelpCenterOverview = React.lazy(() => import('./routes/pages/HelpCenter/HelpCenterOverview'));
const HelpCenterFaqs = React.lazy(() => import('./routes/pages/HelpCenter/HelpCenterFaqs'));
const HelpCenterGuides = React.lazy(() => import('./routes/pages/HelpCenter/HelpCenterGuides'));
const HelpCenterSupportRequest = React.lazy(() => import('./routes/pages/HelpCenter/HelpCenterSupportRequest'));
const Blog = React.lazy(() => import('./routes/pages/Blog/PageBlog'));

function withAppWrapper(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <AppWrapper>
          <WrappedComponent></WrappedComponent>
        </AppWrapper>
      );
    }
  };
}

const routes = [
  {
    path: '/dashboard/elrondlogin',
    component: withAppWrapper(ElrondLogin),
    isWithoutLayout: false,
  },
  {
    path: '/dashboard/elrond',
    component: withAppWrapper(Elrond),
    isWithoutLayout: false,
  },
  {
    path: '/privacy',
    component: Privacy,
    isWithoutLayout: false,
  },
  {
    path: '/terms',
    component: Terms,
    isWithoutLayout: false,
  },
  {
    path: '/ledger',
    component: withAppWrapper(Ledger),
    isWithoutLayout: false,
  },
  {
    path: '/helpcenter',
    component: HelpCenterOverview,
    isWithoutLayout: false,
  },
  {
    path: '/helpcenter-faqs',
    component: HelpCenterFaqs,
    isWithoutLayout: false,
  },
  // {
  //   path: '/helpcenter-guides',
  //   component: HelpCenterGuides,
  //   isWithoutLayout: false,
  // },
  {
    path: '/helpcenter-guides',
    component: Blog,
    isWithoutLayout: false,
  },
  // {
  //   path: "helpcenter-support-request",
  //   component: HelpCenterSupportRequest,
  //   isWithoutLayout: false,
  // },

  { path: '/', component: Home, isWithoutLayout: false, exact: true },
  // { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
