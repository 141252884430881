import React from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { useTheme } from '@material-ui/core';

import { MonetizationOn } from '@material-ui/icons';
import SidebarButtons from './SIdebarButtons';
import CmtImage from '@coremat/CmtImage';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.home'} />,
      type: 'item',
      link: '/home',
      icon: <CmtImage src={'/images/logo-symbol.png'} width="20px" />,
    },
    {
      name: <IntlMessages id={'sidebar.staking'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.dashboard.elrond'} />,
          // icon: <ContractImg fill={theme.palette.primary.main} width="20" />,
          icon: <CmtImage src={'/images/elrond/contract-primary.png'} width="20px" />,
          // icon: <MonetizationOn />,
          type: 'item',
          link: '/dashboard/elrond',
        },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
      <SidebarButtons />
    </PerfectScrollbar>
  );
};

export default SideBar;
