import React, { Component, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
// import Routes from './routes';

// Import Css
import './assets/css/materialdesignicons.min.css';
import './Apps.scss';

import './assets/css/colors/default.css';
import { Router } from '@material-ui/icons';

export const store = configureStore();

import routes from './routesnew';
import Layout from 'components/Layout';
import { ContextProvider } from 'context';

function withLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <AppContextProvider>
          <Provider store={store}>
            <ContextProvider>
              <ConnectedRouter history={history}>
                <Suspense fallback={this.Loader()}>
                  <Switch>
                    {/* <Routes /> */}
                    {routes.map((route, idx) =>
                      route.isWithoutLayout ? (
                        <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                      ) : (
                        <Route path={route.path} exact component={withLayout(route.component)} key={idx} />
                      ),
                    )}
                  </Switch>
                </Suspense>
              </ConnectedRouter>
            </ContextProvider>
          </Provider>
        </AppContextProvider>
      </React.Fragment>
    );
  }
}

// export default withRouter(App);
export default App;
