import React from 'react';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FlagIcon from '@material-ui/icons/Flag';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GitHubIcon from '@material-ui/icons/GitHub';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import { Box, lighten } from '@material-ui/core';
import CmtImage from '../../../../../@coremat/CmtImage';
import { Android, Email, Telegram } from '@material-ui/icons';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  list: {
    borderTop: `1px solid ${theme.palette.sidebar.borderColor}`,
    padding: '30px 24px',
    marginTop: 10,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      padding: '30px 20px',
    },
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease',

    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      width: 40,
      height: 40,
      padding: 3,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiListItemIcon-root': {
        marginTop: 0,
        marginLeft: 0,
      },
    },
    '&:not(:last-child)': {
      marginBottom: 16,
    },

    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      color: lighten(theme.palette.common.black, 0.5),
      marginTop: 15,
      marginLeft: 10,
    },
    '& .MuiListItemText-root': {
      marginLeft: 20,

      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        display: 'none',
      },
    },
  },
}));

const buttons = [
  {
    title: {
      text: 'Join Community',
      color: '#FFFFFF',
    },
    subTitle: {
      text: 'Telegram',
      color: '#FFFFFF',
    },
    icon: <Telegram />,
    backgroundColor: 'rgba(50, 128, 200, 0.7)',
    link: 'https://t.me/partnerstaking',
  },
  {
    title: {
      text: 'Contact Us',
      color: '#D36F1A',
    },
    subTitle: {
      text: 'E-mail',
      color: '#D36F1A',
    },
    icon: <Email />,
    backgroundColor: 'rgba(255, 241, 210, 1)',
    link: 'mailto:support@partnerstaking.com',
  },
];

const SidebarButtons = () => {
  const classes = useStyles();
  return (
    <Box>
      <List className={classes.list} disablePadding>
        {buttons.map((button, index) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            button
            component="a"
            href={button.link}
            target="_blank"
            style={{
              backgroundColor: button.backgroundColor,
              color: button.title.color,
            }}
            className={classes.listItem}>
            <ListItemIcon style={{ color: button.title.color }}>{button.icon}</ListItemIcon>
            <ListItemText
              primary={button.title.text}
              secondary={button.subTitle ? button.subTitle.text : ''}
              secondaryTypographyProps={{
                style: button.subTitle ? { color: button.subTitle.color } : {},
              }}
            />
          </ListItem>
        ))}
      </List>
      <Box ml={10} mr={10}>
        <a
          href="https://play.google.com/store/apps/details?id=dev.peppark.partnerstaking.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </Box>
      <Box ml={13} mr={13}>
        <a href="https://apps.apple.com/us/app/partner-staking/id1558363331" target="_blank">
          <img
            alt="Download on the App Store"
            src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png"
          />
        </a>
      </Box>
    </Box>
  );
};

export default SidebarButtons;
